<template>
  <div>
    <div class="card col-xl-12 col-lg-12">
      <a-modal
        :dialog-style="{
          top: '2%',
        }"
        :title="
          visibleDetailsPN
            ? $t('sms.detailsAutoPnTitle')
            : $t('sms.detailsAutoSmsTitle')
        "
        :visible="visibleDetails || visibleDetailsPN"
        width="60%"
        @cancel="
          () => {
            visibleDetails = false;
            visibleDetailsPN = false;
          }
        "
      >
        <a-list
          item-layout="horizontal"
          :data-source="
            visibleDetailsPN ? optionsPN : visibleDetails ? options : []
          "
        >
          <a-list-item slot="renderItem" slot-scope="item">
            <a-list-item-meta>
              <div slot="description">
                <div v-if="item.text">{{ item.text }}</div>
                <div v-if="item.text2">{{ item.text2 }}</div>
              </div>
              <a slot="title">
                {{ $t(item.tname) }}
              </a>
              <div slot="avatar">
                <a-icon style="font-size: 21px" :type="item.icon" />
              </div>
            </a-list-item-meta>
          </a-list-item>
        </a-list>
        <template slot="footer">
          <a-button
            key="back"
            @click="
              () => {
                visibleDetails = false;
                visibleDetailsPN = false;
              }
            "
          >
            {{ $t("action.annuler") }}
          </a-button>
        </template>
      </a-modal>
      <div class="card-body">
        <div class="ant-page-header-heading font-size-24 mb-3">
          <i class="fa fa-envelope mr-2" />{{ $t("sms.autoSms") }}
          <div style="position: absolute; right: 40px; top: 15px">
            <a-button @click="visibleDetails = true" icon="question-circle">
              {{ $t("sms.deatilsAutoSms") }}
            </a-button>
          </div>
        </div>

        <a-skeleton v-if="loadingData" active :paragraph="{ rows: 6 }" />
        <div v-else class="row">
          <div
            class="col-lg-3 mt-4"
            style="display: flex"
            v-for="item in options"
            :key="item.key"
          >
            <a-tree
              checkable
              :default-expand-all="true"
              v-model="smsSettings[item.key]"
              :disabled="disabledOptions"
            >
              <a-tree-node :icon="item.icon" key="all" :title="$t(item.tname)">
                <a-tree-node key="pere" :title="$t('sms.pere')" />
                <a-tree-node key="mere" :title="$t('sms.mere')" />
              </a-tree-node>
            </a-tree>
          </div>
        </div>
        <a-divider></a-divider>
        <div class="mt-4">
          <a-button
            :type="
              checkSaved || loadingData || loadingSave || disabledOptions
                ? ''
                : 'primary'
            "
            :loading="loadingSave"
            :disabled="
              checkSaved || loadingData || loadingSave || disabledOptions
            "
            @click="
              () => {
                handleSave();
              }
            "
            icon="save"
          >
            {{ $t("all.save") }}
          </a-button>
          <a-button
            class="ml-2"
            :loading="loadingSave"
            :disabled="
              loadingData || loadingSave || disabledOptions || checkSaved
            "
            @click="handleReset"
            icon="undo"
          >
            {{ $t("action.reset") }}
          </a-button>
        </div>
      </div>
    </div>
    <div class="card col-xl-12 col-lg-12 mt-2">
      <div class="card-body mt-3">
        <div class="ant-page-header-heading font-size-24 mb-3">
          <i class="fa fa-envelope mr-2" />{{ $t("sms.autoPn") }}
          <div style="position: absolute; right: 40px; top: 15px">
            <a-button @click="visibleDetailsPN = true" icon="question-circle">
              {{ $t("sms.deatilsAutoSms") }}
            </a-button>
          </div>
        </div>

        <a-skeleton v-if="loadingDataPN" active :paragraph="{ rows: 6 }" />

        <div v-else class="row">
          <div
            class="col-lg-3 mt-4"
            style="display: flex"
            v-for="item in optionsPN"
            :key="item.key"
          >
            <a-checkbox
              :checked="pnSettings[item.key]"
              @change="(e) => (pnSettings[item.key] = e.target.checked)"
            >
              {{ $t(item.tname) }}
            </a-checkbox>
          </div>
        </div>
        <a-divider></a-divider>
        <div class="mt-4">
          <a-button
            :type="
              checkSavedPN || loadingDataPN || loadingSavePN ? '' : 'primary'
            "
            :loading="loadingSave"
            :disabled="checkSavedPN || loadingDataPN || loadingSavePN"
            @click="
              () => {
                handleSavePN();
              }
            "
            icon="save"
          >
            {{ $t("all.save") }}
          </a-button>
          <a-button
            class="ml-2"
            :loading="loadingSavePN"
            :disabled="loadingDataPN || loadingSavePN || checkSavedPN"
            @click="handleResetPN"
            icon="undo"
          >
            {{ $t("action.reset") }}
          </a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
/*eslint-disable*/

import apiClient from "@/services/axios";
import { mapState } from "vuex";

export default {
  name: "sms-parametrage",
  components: {},
  data() {
    return {
      visibleDetails: false,
      loadingData: false,
      loadingSave: false,
      visibleDetailsPN: false,
      loadingDataPN: false,
      loadingSavePN: false,
      activeKey: "1",
      loadKey: 0,

      options: [
        {
          tname: "sms.newPayment",
          key: "payment",
          icon: "dollar",
          text: `Vous avez payé {montant payé} TND les frais du "{Nom de l'activité}" pour {Nom de l'éléve}. Ce paiement est pour la période {liste des mois}.`,
          text2: `Vous avez payé {montant payé} TND les frais de la scolarité pour {Nom de l'éléve}. Ce paiement est pour la période {liste des mois}.`,
        },

        {
          tname: "sms.newInscription",
          key: "inscription",
          icon: "user-add",
          text: "Votre enfant {nom de l'élève} a été inscrit avec succès dans {nom de l'activité}.",
          text2:
            "Votre enfant {nom de l'élève} a été inscrit avec succès dans la classe {nom de la classe}.",
        },
        {
          tname: "sms.newAbsence",
          key: "assiduite",
          icon: "unordered-list",
          text: `Votre enfant {nom de l'élève} était absent le {date} à {temps} durant la séance de "{nom de la séance}`,
          text2: `Votre enfant {nom de l'élève} est arrivé(e) en retard le {date} à {temps} durant la séance de "{nom de la séance}"`,
        },
        {
          tname: "sms.newCourse",
          key: "course",
          icon: "copy",
          text: "Un nouveau cours a été ajouté pour votre enfant avec le titre : {titre}.",
          text2:
            "Un nouveau exercice a été ajouté pour votre enfant avec le titre : {titre}.",
        },
        {
          tname: "sms.bulletinDispo",
          key: "bulletin",
          icon: "wallet",
          text: "Le bulletin {pédagogique/pilote/spécifique} de {nom de l'élève} pour le trimestre {trimestre} est disponible sur EdParent.",
        },
        {
          tname: "sms.actualite",
          key: "actualite",
          icon: "notification",
          text: "Une nouvelle actualité a été ajoutée sur Edparent avec le titre : {titre}.",
        },
        {
          tname: "sms.paymentRappel",
          key: "paymentRappel",
          icon: "bell",
          text: "Rappel : Vous devez régler les frais de scolarité pour le mois de {mois}",
        },
      ],
      optionsPN: [
        {
          tname: "sms.paymentRappel",
          key: "paymentRappel",
          icon: "bell",
          text: "Rappel : Vous devez régler les frais de scolarité pour le mois de {mois}",
        },
      ],
      smsSettings: {
        payment: ["all", "pere", "mere"],
        inscription: ["all", "pere", "mere"],
        assiduite: ["all", "pere", "mere"],
        course: ["all", "pere", "mere"],
        bulletin: ["all", "pere", "mere"],
        actualite: ["all", "pere", "mere"],
        paymentRappel: ["all", "pere", "mere"],
      },
      pnSettings: {
        paymentRappel: true,
      },
      disabledOptions: false,
      solde: 0,
      savedSettings: {},
      savedSettingsPN: {},
    };
  },
  computed: {
    ...mapState(["settings", "user"]),
    checkSaved() {
      return (
        JSON.stringify(this.savedSettings) === JSON.stringify(this.smsSettings)
      );
    },
    checkSavedPN() {
      return (
        JSON.stringify(this.savedSettingsPN) === JSON.stringify(this.pnSettings)
      );
    },
  },
  methods: {
    handleSave() {
      this.loadingSave = true;
      apiClient
        .patch("/buildings/" + this.settings.activeBuildingId, {
          data: { smsSettings: this.smsSettings },
        })
        .then((res) => {
          this.$message.success(this.$t("all.savedChanges"));
          this.savedSettings = JSON.parse(JSON.stringify(this.smsSettings));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingSave = false;
        });
    },
    handleSavePN() {
      this.loadingSavePN = true;
      apiClient
        .patch("/buildings/" + this.settings.activeBuildingId, {
          data: { pnSettings: this.pnSettings },
        })
        .then((res) => {
          this.$message.success(this.$t("all.savedChanges"));
          this.savedSettingsPN = JSON.parse(JSON.stringify(this.pnSettings));
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(this.$t("error.erreur"));
        })
        .finally(() => {
          this.loadingSavePN = false;
        });
    },
    async getSolde() {
      await apiClient
        .get("/sms/solde")
        .then((res) => {
          this.solde = res.data;
          if (this.solde < 1) this.disabledOptions = true;
        })
        .catch((e) => {
          console.log(e);
          this.$message.error(e.response.data.message);
          this.disabledOptions = true;
        })
        .finally(() => (this.loadingStudents = false));
    },

    handleReset() {
      this.smsSettings = JSON.parse(JSON.stringify(this.savedSettings));
    },
    handleResetPN() {
      this.pnSettings = JSON.parse(JSON.stringify(this.savedSettingsPN));
    },
  },
  async created() {
    this.loadingData = true;
    this.loadingDataPN = true;

    await this.getSolde();

    apiClient
      .post("/buildings/filter", {
        query: {
          _id: this.settings.activeBuildingId,
        },
      })
      .then((res) => {
        if (res.data[0]) {
          this.smsSettings = res.data[0].smsSettings;
          this.pnSettings = res.data[0].pnSettings;
        }
        this.loadingData = false;
        this.loadingDataPN = false;
        this.savedSettings = JSON.parse(JSON.stringify(this.smsSettings));
        this.savedSettingsPN = JSON.parse(JSON.stringify(this.pnSettings));
      })
      .catch((e) => {
        console.log(e);
        this.$message.error(this.$t("error.erreur"));
      });
  },
};
</script>
